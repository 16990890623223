/*
 * Application global variables.
 */

// Set Font Awesome font path
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

// ---------------------------------------------------------------------------
// Bootstrap variables
//
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss

//
// Color system
//

$primary: #6cc1ff;
$secondary: #515151;
$accent: #f94848;
$white: #ffffff;
$black: #000000;
$light: #fafafa;
$dark: #212121;
$blue: #141e30;
$blue-light: #a0b7d05c; //#a0b7d0bf;
$surface: #fafafa;

$divider-light: rgba($light, 0.5);
$divider-dark: rgba($dark, 0.5);
$divider-blue-light: rgba($blue-light, 0.5);

$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $light,
  dark: $dark,
);

$link-decoration: none;
$input-btn-focus-width: 0.15rem;

$body-color: #2e2e2e;

$font-family-sans-serif: "Lato", system-ui, -apple-system;

// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/breakpoints";
